import {
  useCartAddItem,
  useCartRemoveItem,
  useCartUpdateItem,
  useProductByHandle,
} from '@backpackjs/storefront';
import { useEffect, useMemo, useState } from 'react';
import { useSubscriptionWidget } from '../../hooks';
import { Image } from '../Image';
import { Link } from '../Link';
import { RechargeSubscriptionWidgetDetails } from '../RechargeSubscriptionWidget/RechargeSubscriptionWidgetDetails';
import { Spinner } from '../Spinner';
import { Svg } from '../Svg';
import { CartItemSubscriptionDropdown } from './CartItemSubscriptionDropdown';
import { QuantitySelector } from './QuantitySelector';
import { useCartItem } from './useCartItem';
import { useCartItemPrices } from './useCartItemPrices';
import { useGlobalContext } from '../../contexts';

export function CartItem({ closeCart, item, setIsUpdating }) {
  const { attributes, id, variant, quantity } = item;

  const {
    state: { testVariant },
  } = useGlobalContext();

  const [selectedSubscription, setSelectedSubscription] = useState(false);

  const {
    isUpdatingItem,
    isRemovingItem,
    handleDecrement,
    handleIncrement,
    handleRemove,
  } = useCartItem({ id, quantity, attributes });
  const { price, compareAtPrice } = useCartItemPrices({ item });
  const { cartAddItem } = useCartAddItem();
  const { cartUpdateItem } = useCartUpdateItem();
  const { cartRemoveItem } = useCartRemoveItem();
  const [tooltipVisible, setTooltipVisible] = useState(false);

  useEffect(() => {
    if (item?.sellingPlanAllocation) {
      setSelectedSubscription(item.sellingPlanAllocation);
    }
  }, [item]);

  const fullProduct = useProductByHandle({
    handle: item.variant.product.handle,
  });

  const variantSellingPlans = useMemo(() => {
    return fullProduct?.product?.variants.filter((productVariant) => {
      return productVariant?.id === item?.variant?.id;
    })[0];
  }, [fullProduct]);

  let sellingPlan = {};

  const updateSubscription = async (option) => {
    setIsUpdating(true);
    sellingPlan = {
      lineId: item.id,
      quantity: item.quantity,
      sellingPlanId: option?.sellingPlan.id,
    };
    setSelectedSubscription(option);
    await cartUpdateItem(sellingPlan).then(() => setIsUpdating(false));
  };

  const addSubscription = async () => {
    setIsUpdating(true);
    sellingPlan = {
      attributes,
      merchandiseId: item.variant.id,
      quantity: item.quantity,
      sellingPlanId:
        variantSellingPlans?.sellingPlanAllocations[0]?.sellingPlan.id,
    };
    setSelectedSubscription(variantSellingPlans?.sellingPlanAllocations[0]);
    await cartRemoveItem({ lineId: item.id });
    await cartAddItem(sellingPlan).then(() => setIsUpdating(false));
  };

  const url = `/products/${variant.product.handle}`;

  const { productSubscriptionWidget } = useSubscriptionWidget(
    item?.variant?.product
  );

  const shipsNow = productSubscriptionWidget?.shipsNow;
  const shipsRecurringly = productSubscriptionWidget?.shipsRecurringly;

  const getItemId = () => {
    if (!item) return null;
    const parts = item.id.split('/');
    const lastPart = parts[parts.length - 1];
    return lastPart.split('?')[0];
  };
  const itemId = getItemId();

  const imageComponent = (
    <Image
      alt={variant.product.title}
      className="w-[70px] shrink-0 bg-offWhite md:w-[90px]"
      height={Math.floor(90 / (variant.image.width / variant.image.height))}
      src={variant.image.src}
      width="90"
    />
  );

  const titleComponent = (
    <h3 className="text-sm font-normal leading-[16px] text-[#24293B]">
      {variant.product.title}
    </h3>
  );

  return (
    <div
      className={
        (shipsNow?.show || shipsRecurringly?.show) &&
        item?.variant &&
        item?.sellingPlanAllocation
          ? 'm-4 border border-text'
          : ''
      }
    >
      <div className="relative m-4 flex flex-wrap items-center gap-1 border-[#DDE2E7]">
        <div className="flex w-full justify-between">
          <div className="aspect-square w-[100px]">
            <Link
              aria-label={`View ${variant.product.title}`}
              href={url}
              onClick={closeCart}
              tabIndex="-1"
            >
              {imageComponent}
            </Link>
          </div>
          <div className="flex min-h-[6.25em] w-1/2 flex-col justify-evenly text-[#202635]">
            <div className="relative">
              <Link
                aria-label={`View ${variant.product.title}`}
                href={url}
                onClick={closeCart}
              >
                {titleComponent}
              </Link>

              {variant?.product?.tags?.includes('Affinity Technology') && (
                <div className="my-1 text-xs font-light uppercase">
                  With Affinity® Filtration Technology
                </div>
              )}
              {variant.title !== 'Default Title' && (
                <p className="mt-1 text-base font-light tracking-[-.01em] opacity-60">
                  {variant.title}
                </p>
              )}
            </div>

            <button
              aria-label={`Remove ${variant.product.title} from cart`}
              className="absolute right-0 top-0 w-3"
              onClick={handleRemove}
              type="button"
            >
              <Svg
                src="/svgs/close.svg#close"
                title="Close"
                viewBox="0 0 24 24"
              />
            </button>

            <div className="flex items-center justify-between gap-3">
              {testVariant['clear-102'] !== 'b' &&
                variantSellingPlans?.sellingPlanAllocations.length > 0 &&
                !item?.sellingPlanAllocation && (
                  <button
                    type="button"
                    className="cursor-pointer py-1 font-[300] text-[#3383C5]"
                    onClick={() => addSubscription()}
                  >
                    <span className="block max-w-max whitespace-nowrap border-b border-[#3383C5] text-[13px]">
                      Subscribe & Save 10%
                    </span>
                  </button>
                )}

              {variantSellingPlans?.sellingPlanAllocations.length > 0 &&
                item?.sellingPlanAllocation?.sellingPlan && (
                  <div className="mt-4 w-full max-w-[198px]">
                    <CartItemSubscriptionDropdown
                      selectedOption={selectedSubscription}
                      options={variantSellingPlans?.sellingPlanAllocations}
                      setSelectedOption={setSelectedSubscription}
                      updateSubscription={updateSubscription}
                    />
                    {/* <p>{item?.sellingPlanAllocation?.sellingPlan.name}</p> */}
                  </div>
                )}
            </div>
          </div>
          <div className="h-full w-1/4 text-primary">
            <div
              className={`flex min-h-[6.25em] flex-col items-end justify-between gap-x-2 ${
                variantSellingPlans?.sellingPlanAllocations.length > 0 &&
                item?.sellingPlanAllocation?.sellingPlan === 0
                  ? 'flex-col'
                  : 'flex-1'
              }`}
            >
              <div className="flex items-start justify-end pt-5">
                {compareAtPrice ? (
                  <p className="mr-2 text-xs font-normal line-through opacity-60 md:mb-2">
                    {compareAtPrice}
                  </p>
                ) : (
                  variantSellingPlans?.sellingPlanAllocations.length > 0 &&
                  item?.sellingPlanAllocation?.sellingPlan && (
                    <p className="mr-2 text-xs font-normal line-through opacity-60 md:mb-2">
                      $
                      {Number(
                        item?.sellingPlanAllocation?.priceAdjustments[0]
                          .compareAtPrice?.amount
                      ).toFixed(2)}
                    </p>
                  )
                )}
                <p className="text-xs font-normal md:mb-2">{price}</p>
              </div>
              <QuantitySelector
                handleDecrement={handleDecrement}
                handleIncrement={handleIncrement}
                isUpdatingItem={isUpdatingItem}
                item={item}
              />
            </div>
          </div>
        </div>

        {isRemovingItem && (
          <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center bg-[rgba(255,255,255,0.6)] text-mediumGray">
            <Spinner />
          </div>
        )}

        {testVariant['clear-102'] === 'b' &&
          variantSellingPlans?.sellingPlanAllocations.length > 0 &&
          !item?.sellingPlanAllocation && (
            <div className="relative mb-8 mt-2 flex w-full rounded-sm border border-secondary bg-gradient-to-r from-[#fcf8f3] via-[#e7f4fd] to-[#d3e4f3] px-4 py-[0.4rem]">
              <label
                htmlFor={`subscribe-checkbox-${itemId}`}
                className="subscribe-unlock__content flex w-full cursor-pointer items-center text-[13px]"
              >
                <input
                  id={`subscribe-checkbox-${itemId}`}
                  type="checkbox"
                  className="custom-checkbox peer hidden"
                  onClick={() => addSubscription()}
                />
                <span className="custom-checkbox-checkmark relative mr-2 flex h-4 w-4 items-center justify-center rounded-[1px] border border-solid border-secondary transition-[background] duration-[0.3s] peer-[:checked]:bg-secondary" />
                <p className="font-normal text-secondary">
                  Subscribe & Unlock 10% Off
                </p>
              </label>
              <button
                type="button"
                className={`subscribe-unlock__icon relative flex w-full flex-1 cursor-pointer items-center justify-end ${
                  tooltipVisible ? 'active' : ''
                }`}
                aria-label="Toggle Tooltip"
                aria-expanded={tooltipVisible}
                aria-controls="toggle-tooltip"
                onClick={() => setTooltipVisible((prev) => !prev)}
                onMouseEnter={() => setTooltipVisible(true)}
                onMouseLeave={() => setTooltipVisible(false)}
              >
                <Svg
                  className={`w-[14px] ${
                    tooltipVisible ? 'text-accent2' : 'text-secondary'
                  }`}
                  src="/svgs/tooltip.svg#tooltip"
                  title="Tooltip"
                  viewBox="0 0 12 12"
                />

                <div
                  id="toggle-tooltip"
                  className={`subscribe-unlock__tooltip absolute -top-[7.1rem] right-[-1.2rem] block w-60 rounded-sm bg-clearBlue p-4 shadow-[0_0.4rem_1rem_rgba(0,0,0,0.2)] ${
                    tooltipVisible ? '' : 'hidden'
                  }`}
                  role="tooltip"
                  hidden={!tooltipVisible}
                >
                  <div className="subscribe-unlock__tooltip__inner relative before:absolute before:bottom-[-2.3rem] before:right-[-1.5rem] before:z-[1] before:block before:h-8 before:w-8 before:-translate-x-2/4 before:-translate-y-2/4 before:rotate-45 before:bg-clearBlue before:content-['']">
                    <ul className="relative z-[1] list-disc pl-2 text-left font-light">
                      <li className="text-xs leading-6 text-[#343b49]">
                        10% off first purchase and refills
                      </li>
                      <li className="text-xs leading-6 text-[#343b49]">
                        Free shipping on all refills (U.S. only)
                      </li>
                      <li className="text-xs leading-6 text-[#343b49]">
                        Pause, Skip, or Cancel any time.
                      </li>
                    </ul>
                  </div>
                </div>
              </button>
            </div>
          )}
      </div>

      {(shipsNow?.show || shipsRecurringly?.show) &&
        item?.variant &&
        item?.sellingPlanAllocation && (
          <RechargeSubscriptionWidgetDetails
            className="lg:!p-4"
            item={item}
            selectedVariant={item?.variant}
            selectedSellingPlan={item?.sellingPlanAllocation}
            shipsNow={productSubscriptionWidget?.shipsNow}
            shipsRecurringly={productSubscriptionWidget?.shipsRecurringly}
            expandable
          />
        )}
    </div>
  );
}

CartItem.displayName = 'CartItem';
